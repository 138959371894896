import React from "react";
import styled from "styled-components";

import { priceFormat } from "../../lib/helpers";
import { styles } from "../../lib/utils";
import Image from "../Layout/image"

// destructring the product off of props, so we don't have to keep writing props.product
const Product = ({ id, name, price, description, imgID, alt }) => {
  return (
    <ProductWrapper key={id}>
      <Image className="img" alt={name || alt} imgID={imgID} />
      <div className="text">
        <div className="product-content">
          <h3 className="name">{name}</h3>
        </div>
        <h4 className="price">{priceFormat(price)}</h4>
        <p className="info">{description}</p>
      </div>
    </ProductWrapper>
  )
};

const ProductWrapper = styled.div`
  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 1rem;
  }
  .img {
    @media (min-width: 576px) {
      width: 250px;
      height: 250px;
    }
    width: 100%;
    height: 300px;
    border-radius: 0.5rem;
  }
  .product-content {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  .name {
    margin-top: 0.5rem;
  }
  .price {
    color: ${styles.colors.mainYellow};
    margin-bottom: 1rem;
  }
  .info {
    margin-top: 0.5rem;
    word-spacing: 0.2rem;
    @media (min-width: 576px) {
      word-spacing: 0.3rem;
    }
  }
  .text {
    padding: 10px;
  }
`;

export const ProductList = styled.div`
  margin: 3rem 0;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 3rem;

  @media (min-width: 576px) {
    grid-template-columns: 95%;
  }

  @media (min-width: 776px) {
    grid-template-columns: 80%;
    justify-content: center;
  }

  @media (min-width: 992px) {
    /* 1fr 1fr -- working with fractions, so this means it would be 2 coloumns */
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`;

export default Product;
