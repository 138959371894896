/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import { Link } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from "styled-components"
import 'slick-carousel/slick/slick-theme.css';
import { any, arrayOf } from 'prop-types';

import Image from '../Layout/image';
import { Section, Title } from '../../lib/utils';
import Product from "../general/Product"

const RECOMMENDED_MENU = [
  {
    id: 1,
    name: 'Babi Panggang Karo',
    price: 25000,
    description: 'Babi Panggang Karo (Pork Roasted) merupakan makanan tradisional khas Medan. Komposisi hidangan ini berupa Babi Panggang Karo, sayur daun ubi, cabe andaliman segar dan Sup B2 hangat.',
    imgID: 'MenuBPK',
  },
  {
    id: 2,
    name: 'Kidu-Kidu',
    price: 25000,
    description: 'Kidu-kidu merupakan makanan tradisional khas Medan. Komposisi hidangan ini berupa Kidu-kidu, sayur daun ubi, cabe andaliman segar dan Sup B2 hangat.',
    imgID: 'MenuKidu',
  },
  {
    id: 3,
    name: 'Sup Isi B2',
    price: 25000,
    description: 'Sup Isi B2 merupakan makanan tradisional khas Medan. Komposisi hidangan ini berupa Sup isi B2, sayur daun ubi, cabe andaliman segar.',
    imgID: 'MenuSoup',
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "darkgrey", borderRadius: '100%', right: '-10px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "darkgrey", borderRadius: '100%', left: '-10px', zIndex: 1 }}
      onClick={onClick}
    />
  );
}

const Banner = ({ items }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <Section>
      <Title title="Hidangan Utama" />
      <Slider {...settings} style={{ marginTop: 30 }}>
        {items.map(menu => (
          <ProductList>
            <Product {...menu} />
          </ProductList>
        ))}
      </Slider>
    </Section>
  );
};

export const ProductList = styled.div`
  border-radius: 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 3rem;

  @media (min-width: 576px) {
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    grid-template-columns: 95%;
  }

  @media (min-width: 776px) {
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    grid-template-columns: 80%;
    justify-content: center;
  }

  @media (min-width: 992px) {
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    /* 1fr 1fr -- working with fractions, so this means it would be 2 coloumns */
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`;

Banner.propTypes = {
  items: arrayOf(any),
}

Banner.defaultProps ={
  items: RECOMMENDED_MENU,
}

export default Banner;